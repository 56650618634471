import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuth } from "./hrmsNotifications";
import Apis from "../../utils/apis";

export const teamRoleExpense = createApi({
  reducerPath: "expenseTeamList",
  baseQuery: baseQueryWithAuth,
  tagTypes: ["ExpensesTeamRole"],
  endpoints: (builder) => ({
    getExpenseTeamData: builder.query({
      query: ({
        page = 1,
        limit = 10,  // Default limit added here
        pagination = true,
        role = []
      }) => ({
        url: `${Apis.teamRoleExpense}`,
        params: {
          page,
          limit,
          pagination,
          ...role.length && { role },
        },
      }),
      providesTags: ["ExpensesTeamRole"],
      keepUnusedDataFor: 300,  // 5 minutes cache
    }),
  }),
});

export const { useGetExpenseTeamDataQuery } = teamRoleExpense;  // Changed to camelCase convention
