import React, { useCallback, useState } from "react";
import {
  Box,
  IconButton,
  Menu,
  Button,
  Typography,
  MenuItem,
} from "@mui/material";
import {
  Notifications,
  MiscellaneousServices,
  Engineering,
  Dashboard,
} from "@mui/icons-material";
import LogoutIcon from "@mui/icons-material/Logout";
import KeyIcon from "@mui/icons-material/Key";
import BasicDialog from "../components/BasicDialog";
import ChangePassword from "../components/Dashboard/ChangePassword";
import Notification from "./Notification";
import Logo from "./Logo";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { logout } from "../store/reducers/auth";
import { isAdminSrmanager, navItems } from "../utils/menuItems";
import { useSelector } from "react-redux";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import Slide from "@mui/material/Slide";
import SearchIcon from "@mui/icons-material/Search";
import AdvanceSearch from "./AdvanceSearch";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DashboardCustomizeIcon from "@mui/icons-material/DashboardCustomize";
import HrmsButton from "./Dashboard/HrmsButton";

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="left"
      ref={ref}
      {...props}
      sx={{
        width: "70%",
        "@media(max-width: 1200px)": {
          marginLeft: "16rem",
          "@media(max-width: 900px)": {
            marginLeft: "17rem",
          },
          "@media(max-width: 600px)": {
            marginLeft: "9rem",
          },
        },
      }}
    />
  );
});

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { auth } = useSelector((state) => state.auth);
  const { pathname } = useLocation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [notificationopen, setNotificationOpen] = React.useState(null);
  const [openChangePassword, setopenChangePassword] = useState(false);
  const open = Boolean(anchorEl);
  const openNotification = Boolean(notificationopen);
  const [opendilog, setOpendilog] = React.useState(false);
  const [openAdvanceSearch, setOpenAdvanceSearch] = useState(false);
  const [accountsOpen, setAccountsOpen] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  /********notification Menu *******/
  const handleClickNotification = (event) => {
    setNotificationOpen(event.currentTarget);
  };
  const handleCloseNotification = () => {
    setNotificationOpen(null);
  };

  const handleClickOpendilog = () => {
    setOpendilog(true);
  };

  const handleClosedilog = () => {
    setOpendilog(false);
  };

  const handleLogout = useCallback(() => {
    localStorage.clear();
    dispatch(logout());
    navigate(0);
  }, [dispatch, navigate]);

  /****************change password ***********/
  const handleChangePassword = () => {
    setopenChangePassword(true);
  };
  const changepasswordClose = () => {
    setopenChangePassword(false);
  };

  /****************Advance Search ***********/
  const handleAdvanceSearch = () => {
    setOpenAdvanceSearch(true);
  };
  const handleAdvanceSearchClose = () => {
    setOpenAdvanceSearch(false);
  };

  const toggleAccountsMenu = (e) => {
    e.preventDefault();
    setAccountsOpen(!accountsOpen); // Toggle the state
  };

  return (
    <Box sx={{ background: "#010006", color: "white", p: "1.5rem", mb: 1 }}>
      <Box
        sx={{
          display: { xs: "none", md: "flex" },
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Link to="/dashboard">
            {" "}
            <Logo />
          </Link>
          <Link to="/dashboard">
            {" "}
            <Box sx={{ fontWeight: "bold", fontSize: "1.5rem" }}>
              AXP Connect
            </Box>
          </Link>
        </Box>

        <Box>
          <Box sx={{ display: "flex", gap: 3 }}>
            <SearchIcon
              onClick={handleAdvanceSearch}
              sx={{ width: 30, height: 30, cursor: "pointer" }}
            />

            <Notifications
              onClick={handleClickNotification}
              sx={{ width: 30, height: 30, cursor: "pointer" }}
            />
            <Menu
              id="basic-menu"
              anchorEl={notificationopen}
              open={openNotification}
              onClose={handleCloseNotification}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
              MenuListProps={{
                "aria-labelledby": "basic-menu",
              }}
              PaperProps={{
                style: {
                  width: 500,
                },
              }}
            >
              <Notification onClose={handleCloseNotification} />
            </Menu>
            <MiscellaneousServices
              onClick={handleClick}
              sx={{ width: 30, height: 30, cursor: "pointer" }}
            />
          </Box>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <Box sx={{ display: "flex" }} onClick={handleChangePassword}>
              <MenuItem>
                <KeyIcon sx={{ color: "#FFC857" }} />
              </MenuItem>
              <MenuItem>Change Password</MenuItem>
            </Box>
            <BasicDialog
              open={openChangePassword}
              handleClose={changepasswordClose}
            >
              <ChangePassword handleClose={changepasswordClose} />
            </BasicDialog>

            {/* Developers Section */}
            {(auth?.role === "sup_admin" || auth?.role === "sub_admin") && (
              <Link to={"/developers"}>
                <Box sx={{ display: "flex" }}>
                  <MenuItem>
                    <Engineering sx={{ color: "#FFC857" }} />
                  </MenuItem>
                  <MenuItem>Developers</MenuItem>
                </Box>
              </Link>
            )}

            {(auth?.role === "sup_admin" || auth?.role === "sub_admin") && (
              <Link to={"/"}>
                <Box sx={{ display: "flex" }}>
                  <MenuItem>
                    <Dashboard sx={{ color: "#FFC857" }} />
                  </MenuItem>
                  <MenuItem onClick={toggleAccountsMenu}>Accounts</MenuItem>
                </Box>
              </Link>
            )}

            {accountsOpen && (
              <Box sx={{ paddingLeft: 2 }}>
                {/* <Link to={"/expense/expense-list"}>
                  <MenuItem>Expenses</MenuItem>
                </Link> */}
                <Link to={"/expense/expense-list"}>
                  <Box sx={{ display: "flex" }}>
                    <MenuItem>
                      <Dashboard sx={{ color: "#FFC857" }} />
                    </MenuItem>
                    <MenuItem>Expenses</MenuItem>
                  </Box>
                </Link>
                <Link to={"/invoice"}>
                  <Box sx={{ display: "flex" }}>
                    <MenuItem>
                      <Dashboard sx={{ color: "#FFC857" }} />
                    </MenuItem>
                    <MenuItem>Invoice</MenuItem>
                  </Box>
                </Link>

                {/* <Link to={"/invoice/invoice-list"}>
                  <MenuItem>Invoice</MenuItem>
                </Link> */}
              </Box>
            )}

            <HrmsButton />
            <Box sx={{ display: "flex" }} onClick={handleLogout}>
              <MenuItem>
                <LogoutIcon sx={{ color: "#FFC857" }} />
              </MenuItem>
              <MenuItem>Logout</MenuItem>
            </Box>
          </Menu>
        </Box>
      </Box>
      <BasicDialog
        fullScreen={true}
        sx={{
          width: "96%",
          height: "80%",
          margin: "auto",
          marginTop: 12,
          "& .MuiPaper-root": {
            borderRadius: "11.7px",
            border: "1px solid #FFC857",
          },
          "& .MuiDialogContent-root::-webkit-scrollbar": {
            display: "none",
          },
        }}
        open={openAdvanceSearch}
        handleClose={handleAdvanceSearchClose}
      >
        <AdvanceSearch
          handleClose={handleAdvanceSearchClose}
          title={"Advance Search"}
        />
      </BasicDialog>

      <Box
        sx={{
          display: {
            xs: "block",
            md: "none",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            textAlign: "center",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Link to="/dashboard">
              {" "}
              <Logo />
            </Link>
            <Link to="/dashboard">
              {" "}
              <Box sx={{ fontWeight: "bold", fontSize: "1.5rem" }}>
                AXP Connect
              </Box>
            </Link>
          </Box>
          <Box sx={{ display: "flex", gap: 2 }}>
            <SearchIcon
              onClick={handleAdvanceSearch}
              sx={{
                width: 30,
                height: 30,
                cursor: "pointer",
                marginTop: "7px",
              }}
            />
            <Notifications
              onClick={handleClickNotification}
              sx={{
                width: 30,
                height: 30,
                cursor: "pointer",
                marginTop: "7px",
              }}
            />
            <Box sx={{ marginTop: "7px" }}>
              <MenuIcon
                onClick={handleClickOpendilog}
                sx={{
                  color: "#fff !important",
                  cursor: "pointer",
                  fontSize: "25px",
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Dialog
        fullScreen
        open={opendilog}
        onClose={handleClosedilog}
        TransitionComponent={Transition}
        sx={{
          backgroundImage: "none",
          "& .MuiPaper-root": {
            backgroundColor: "#fff",
          },
        }}
      >
        <AppBar
          sx={{
            position: "relative",
            backgroundColor: "#eeeee8",
            boxShadow: "none",
          }}
        >
          <Toolbar
            sx={{
              backgroundColor: "black",
              height: "5.5rem !important",
              boxShadow: "none",
              justifyContent: "right",
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClosedilog}
              aria-label="close"
            >
              <CloseIcon sx={{ marginRight: "4rem" }} />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box
          sx={{
            textAlign: "left",
            gap: "20px !important",
            p: "0rem 1rem",
            fontSize: "14px",
          }}
        >
          <Box
            sx={{
              ml: "auto",
              flexDirection: "column",
              gap: "10px",
              padding: "2rem 12px",
              display: { xs: "flex", md: "none" },
            }}
          >
            {navItems
              .filter((item) => {
                if (
                  auth?.role === "agent" &&
                  (item.title === "Users" || item.title === "Teams")
                ) {
                  return false;
                } else if (
                  (auth?.role === "sup_admin" ||
                    auth?.role === "sub_admin" ||
                    auth?.role === "sr_manager") &&
                  item.title === "Teams"
                ) {
                  return false;
                } else if (
                  (auth?.role === "manager" || auth?.role === "team_lead") &&
                  item.title === "Users"
                ) {
                  return false;
                } else {
                  return item;
                }
              })
              .map((item) =>
                pathname.includes(item.url) ? (
                  <Link to={item.url} key={item.url}>
                    <Button
                      variant="contained"
                      color="custom"
                      startIcon={item.icon}
                      sx={{
                        width: "70%",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        fontWeight: "bold",
                        textTransform: "unset",
                      }}
                    >
                      {item.title}
                    </Button>
                  </Link>
                ) : (
                  <Link to={item.url} key={item.url} onClick={handleClosedilog}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        fontWeight: "bold",
                        gap: 1,
                        p: 1,
                        borderRadius: 2,
                      }}
                    >
                      {item.icon}
                      <Box>{item.title}</Box>
                    </Box>
                  </Link>
                )
              )}
          </Box>
          <BasicDialog
            open={openChangePassword}
            handleClose={changepasswordClose}
          >
            <ChangePassword handleClose={changepasswordClose} />
          </BasicDialog>
          <Box
            sx={{
              display: "flex",
              gap: 1,
              color: "black",
              cursor: "pointer",
              padding: "0rem 22px",
              marginTop: "-23px",
              lineHeight: "2.5",
              fontSize: "14px",
            }}
            onClick={handleChangePassword}
          >
            <Typography>
              <KeyIcon />
            </Typography>
            <Typography
              sx={{
                fontWeight: "700",
                "@media(max-width: 900px)": {
                  fontSize: "14px !important",
                  marginTop: "5px",
                  "@media(max-width: 600px)": {
                    fontSize: "14px !important",
                  },
                },
              }}
            >
              Change Password
            </Typography>
          </Box>

          <Link to={"/developers"}>
            <Box
              sx={{
                display: "flex",
                gap: 1,
                color: "black",
                cursor: "pointer",
                padding: "4px 22px",
                marginTop: "9px",
                fontSize: "14px",
              }}
            >
              <Typography>
                <Engineering />
              </Typography>
              <Typography
                sx={{
                  fontWeight: "700",
                  marginTop: "4px",
                  "@media(max-width: 900px)": {
                    fontSize: "14px !important",
                    "@media(max-width: 600px)": {
                      fontSize: "14px !important",
                    },
                  },
                }}
              >
                Developers
              </Typography>
            </Box>
          </Link>

          {/*************************HRMS Link****************** */}
          <Link to={"/HrManagement/dashboard"}>
            <Box
              sx={{
                display: "flex",
                gap: 1,
                color: "black",
                cursor: "pointer",
                padding: "4px 22px",
                marginTop: "9px",
                fontSize: "14px",
              }}
            >
              <Typography>
                <DashboardCustomizeIcon />
              </Typography>
              <Typography
                sx={{
                  fontWeight: "700",
                  marginTop: "4px",
                  "@media(max-width: 900px)": {
                    fontSize: "14px !important",
                    "@media(max-width: 600px)": {
                      fontSize: "14px !important",
                    },
                  },
                }}
              >
                HRMS
              </Typography>
            </Box>
          </Link>

          <Box
            sx={{
              display: "flex",
              gap: 1,
              color: "black",
              cursor: "pointer",
              padding: "4px 22px",
              marginTop: "9px",
              fontSize: "14px",
            }}
            onClick={handleLogout}
          >
            <Typography>
              <LogoutIcon />
            </Typography>
            <Typography
              sx={{
                fontWeight: "700",
                marginTop: "4px",
                "@media(max-width: 900px)": {
                  fontSize: "14px !important",
                  "@media(max-width: 600px)": {
                    fontSize: "14px !important",
                  },
                },
              }}
            >
              Logout
            </Typography>
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
};

export default Header;
