import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuth } from "./hrmsNotifications";
import Apis from "../../utils/apis";

export const invoiceList = createApi({
  reducerPath: "invoiceList",
  baseQuery: baseQueryWithAuth,
  tagTypes: ["Invoice"],
  endpoints: (builder) => ({
    getInvoiceData: builder.query({
      query: ({
        page = 1,
        limit,
        type,
        status, 
        search,
        pagination = true,
      }) => ({
        url: `${Apis.invoiceList}`,
        params: {
          page,
          limit,
          type,
          status, 
          search,
          pagination, 
        },
      }),
      providesTags: ["Invoice"],
      keepUnusedDataFor: 300,
    }),
  }),
});

export const { useGetInvoiceDataQuery } = invoiceList;
