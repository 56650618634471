import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./reducers/auth";
import userReducer from "./reducers/user";
import leadReducer from "./reducers/lead";
import teamReducer from "./reducers/team";
import meetingReducer from "./reducers/meeting";
import bookingReducer from "./reducers/booking";
import developerReducer from "./reducers/developer";
import queryReducer from "./reducers/query";
import queryDataReducer from "./reducers/queryData";
import { notificationApi } from "./services/notification";
import { hrmsNotificationApi } from "./services/hrmsNotifications";
import { creditLeavesApi } from "./services/creditLeavesData";
import { latestAuthApi } from "./services/authService";
import { attendaceApi } from "./services/attendance";
import { leaveApi } from "./services/leaves";
import { userApi } from "./services/user";
import { leadsApi } from "./services/leads";
import { leadDetailsApi } from "./services/leadDetail";
import { assignUsersAPi } from "./services/assigningUsers";
import { meetingsApi } from "./services/meetings";
import { meetingDetailsApi } from "./services/meetingDetail";
import { bookingsApi } from "./services/booking";
import { meetingsFrBApi } from "./services/meetingsForBooking";
import { bookingDetailsApi } from "./services/bookingDetail";
import { dashboardDataApi } from "./services/dashboardData";
import { dashboardMeetingApi } from "./services/meetingDashboard";
import { dashboardBookingApi } from "./services/bookingDashboard";
import { commissionDataApi } from "./services/commissionData";
import { projectsList } from "./services/project";
import { projectDetailsApi } from "./services/projectDetails";
import { expenseList } from "./services/expense";
import { expenseCatList } from "./services/expenseCat";
import { expenseCatDetailsApi } from "./services/expenseCatDetails";
import { expenseListDetailsApi } from "./services/expenseListDetails";
import { invoiceList } from "./services/Invoice";
import { invoiceDetailsApi } from "./services/invoiceDetails";
import { teamRoleExpense } from "./services/teamRoleExpense";

export const store = configureStore({
  reducer: {
    [notificationApi.reducerPath]: notificationApi.reducer,
    [hrmsNotificationApi.reducerPath]: hrmsNotificationApi.reducer,
    [creditLeavesApi.reducerPath]: creditLeavesApi.reducer,
    [latestAuthApi.reducerPath]: latestAuthApi.reducer,
    [attendaceApi.reducerPath]: attendaceApi.reducer,
    [leaveApi.reducerPath]: leaveApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [leadsApi.reducerPath]: leadsApi.reducer,
    [meetingsApi.reducerPath]: meetingsApi.reducer,
    [leadDetailsApi.reducerPath]: leadDetailsApi.reducer,
    [meetingDetailsApi.reducerPath]: meetingDetailsApi.reducer,
    [assignUsersAPi.reducerPath]: assignUsersAPi.reducer,
    [bookingsApi.reducerPath]: bookingsApi.reducer,
    [meetingsFrBApi.reducerPath]: meetingsFrBApi.reducer,
    [bookingDetailsApi.reducerPath]: bookingDetailsApi.reducer,
    [dashboardDataApi.reducerPath]: dashboardDataApi.reducer,
    [dashboardMeetingApi.reducerPath]: dashboardMeetingApi.reducer,
    [dashboardBookingApi.reducerPath]: dashboardBookingApi.reducer,
    [commissionDataApi.reducerPath]: commissionDataApi.reducer,
    [projectsList.reducerPath] : projectsList.reducer,
    [projectDetailsApi.reducerPath]: projectDetailsApi.reducer,
    [expenseList.reducerPath]: expenseList.reducer,
    [expenseCatList.reducerPath]: expenseCatList.reducer,
    [expenseCatDetailsApi.reducerPath]: expenseCatDetailsApi.reducer,
    [expenseListDetailsApi.reducerPath]: expenseListDetailsApi.reducer,
    [invoiceList.reducerPath]: invoiceList.reducer,
    [invoiceDetailsApi.reducerPath]: invoiceDetailsApi.reducer,
    [teamRoleExpense.reducerPath]: teamRoleExpense.reducer,
    auth: authReducer,
    users: userReducer,
    leads: leadReducer,
    meetings: meetingReducer,
    teams: teamReducer,
    bookings: bookingReducer,
    developers: developerReducer,
    query: queryReducer,
    advanceSearch: queryDataReducer,
  },
  middleware: (getDefaultMiddleware) =>
    [...getDefaultMiddleware()].concat(
      notificationApi.middleware,
      hrmsNotificationApi.middleware,
      creditLeavesApi.middleware,
      latestAuthApi.middleware,
      attendaceApi.middleware,
      leaveApi.middleware,
      userApi.middleware,
      leadsApi.middleware,
      leadDetailsApi.middleware,
      assignUsersAPi.middleware,
      meetingsApi.middleware,
      meetingDetailsApi.middleware,
      bookingsApi.middleware,
      meetingsFrBApi.middleware,
      bookingDetailsApi.middleware,
      dashboardDataApi.middleware,
      dashboardMeetingApi.middleware,
      dashboardBookingApi.middleware,
      commissionDataApi.middleware,
      projectsList.middleware,
      projectDetailsApi.middleware,
      expenseList.middleware,
      expenseCatList.middleware,
      expenseCatDetailsApi.middleware,
      expenseListDetailsApi.middleware,
      invoiceList.middleware,
      invoiceDetailsApi.middleware,
      // expenseCatList.middleware,
      teamRoleExpense.middleware,

    ),
});
