import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuth } from "./hrmsNotifications";
import Apis from "../../utils/apis";

export const invoiceDetailsApi = createApi({
  reducerPath: "invoiceDetails",
  baseQuery: baseQueryWithAuth,
  tagTypes: ["InvoiceDetail"],
  endpoints: (builder) => ({
    getInvoiceDetailsById: builder.query({
      query: (id) => ({
        url: `${Apis.invoiceDetails}/${id}`,
      }),
      providesTags: ["InvoiceDetail"],
      keepUnusedDataFor: 300,
    }),
  }),
});



export const {  useGetInvoiceDetailsByIdQuery } = invoiceDetailsApi;
