import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuth } from "./hrmsNotifications";
import Apis from "../../utils/apis";

export const expenseList = createApi({
  reducerPath: "expenseList",
  baseQuery: baseQueryWithAuth,
  tagTypes: ["Expenses"],
  endpoints: (builder) => ({
    getExpenseData: builder.query({
      query: ({
        page = 1,
        limit,
        type,
        status,
        individual,
        search,
        pagination = true,
        
      }) => ({
        url: `${Apis.expanseList}`,
        params: {
          page,
          limit,
          type,
          status,
          individual,
          search,
          pagination, 
        },
      }),
      providesTags: ["Expenses"],
      keepUnusedDataFor: 300,
    }),
  }),
});

export const { useGetExpenseDataQuery } = expenseList;
